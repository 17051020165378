import React, { useState, useEffect } from "react"
import { Link, navigate } from "gatsby"

import { isSignedIn, getUser } from "../utils/auth"

import Layout from "../elements/Layout"
import Helmet from "../elements/Helmet"

import Nav from "../components/Nav"

import { Container, Grid, Ul } from "../components/index/styled"

const Index = () => {
  const [authenticating, setAuthenticating] = useState(true)

  useEffect(() => {
    if (!isSignedIn()) {
      navigate("/signin")
    } else {
      setAuthenticating(false)
    }
  }, [])

  return (
    <>
      {authenticating ? (
        <div />
      ) : (
        <Layout>
          <Helmet />
          <Container>
            <Nav
              sections={[
                { name: "Admin Home", to: "" },
                { name: "", to: "" },
                { name: "Sign Out", to: "/signout" },
              ]}
              title=""
            />

            <Grid>
              <div>
                <h3>BFC Donations</h3>

                <Ul>
                  <li>
                    <a
                      href={`https://theclassiccares.org/birdies/donate/?token=${
                        getUser().token
                      }`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Enter a new donation
                    </a>
                  </li>
                  <li>
                    <Link to="/donations">Donation details & guesses</Link>
                  </li>
                  <li>
                    <Link to="/donation-reports">Donation reports</Link>
                  </li>
                </Ul>
              </div>

              <div>
                <h3>BFC Charities</h3>

                <Ul>
                  <li>
                    <Link to="/charity-add">Add a new charity</Link>
                  </li>
                  <li>
                    <Link to="/charities">Charity details & logo</Link>
                  </li>

                  <li>
                    <a
                      href="https://thc.web.app"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Charity sign-up admin
                    </a>
                  </li>
                </Ul>
              </div>
            </Grid>
          </Container>
        </Layout>
      )}
    </>
  )
}

export default Index
