import styled from "styled-components"
import {
  Container as BaseContainer,
  Grid as BaseGrid,
} from "../../elements/Layout/styled"

export const Container = styled(BaseContainer)``

export const Grid = styled(BaseGrid)`
  grid-template-columns: 1fr 1fr;
  justify-items: start;
  grid-gap: 25px;

  & > div {
    width: 100%;
  }

  & > div > h3 {
    margin-bottom: 2px;
  }

  @media only screen and (max-width: 767px) {
  }
`
export const Ul = styled.ul`
  border: 1px solid #ddd;

  & > li:first-child {
    padding-top: 7.5px;
  }

  & > li:last-child {
    padding-bottom: 7.5px;
  }

  & > li {
    width: 100%;
    padding: 2.5px 15px 2.5px 20px;
  }
`
